import React from "react"
import Link from "gatsby-link"
import Languages, { urls } from "./languages"
import Logo from "./logo"
import * as css from "./nav.module.scss"

export default function Nav(props) {
    let navVariant = 'light';
    if (typeof window !== "undefined") {
        const urlSegments = window.location.pathname.split('/').filter(segment => segment !== '' && segment !== 'en');
        navVariant = urlSegments.length === 0 ? 'light' : 'dark';
    }
    const currentLang = props.lang
    return (
        <div className={css.navigation}>
            <input type="checkbox" className={css.navigation__checkbox} id="navi-toggle" />
            <Link to={"/"} className={`${css.navigation__logoMobile}`}>
                <Logo color={"hsl(174, 73%, 35%)"} />
            </Link>

            <label htmlFor="navi-toggle" className={css.navigation__button}>
                <span className={css.navigation__icon}>&nbsp;</span>
            </label>

            <div className={css.navigation__background}>&nbsp;</div>
            <div className={css.navigation__overlay}>&nbsp;</div>

            <nav className={css.navigation__nav} id="top-nav">
                <div>
                    <Link className={`${css.navigation__logo}`} to={"/"}>
                        <div className={css.navigation__logoWhite}>
                            <Logo color={"white"} />
                        </div>
                        <div className={`${css.navigation__logoGreen} logo-hover-${navVariant === 'light' ? 'white' : 'black'}`}>
                            <Logo color={navVariant === 'light' ? "#005C5D" : "#18988B"} />
                        </div>
                    </Link>
                </div>
                <div className={css.navigation__listContainer}>
                    <ul className={css.navigation__list} id={"nav-list"}>
                        <Link to={urls[currentLang].start.url}
                            className={`${css.navigation__link} ${navVariant === "light" ? css.navigation__linkLight : css.navigation__linkDark}`}
                            activeClassName={navVariant === "light" ? css.currentUrlLight : css.currentUrlDark}
                        >
                            {urls[currentLang].start.name}
                        </Link>
                        <Link to={urls[currentLang].about.url}
                            className={`${css.navigation__link} ${navVariant === "light" ? css.navigation__linkLight : css.navigation__linkDark}`}
                            activeClassName={navVariant === "light" ? css.currentUrlLight : css.currentUrlDark}
                        >
                            {urls[currentLang].about.name}
                        </Link>
                        <Link to={urls[currentLang].projects.url}
                            className={`${css.navigation__link} ${navVariant === "light" ? css.navigation__linkLight : css.navigation__linkDark}`}
                            activeClassName={navVariant === "light" ? css.currentUrlLight : css.currentUrlDark}
                            partiallyActive={true}
                        >
                            {urls[currentLang].projects.name}
                        </Link>
                        <Link
                            to={urls[currentLang].contact.url}
                            className={`${css.navigation__link} ${navVariant === "light" ? css.navigation__linkLight : css.navigation__linkDark}`}
                            activeClassName={navVariant === "light" ? css.currentUrlLight : css.currentUrlDark}>
                            {urls[currentLang].contact.name}
                        </Link>
                    </ul>

                </div>
                <div className={css.navigation__footer}>
                    <p className={css.navigation__footer__copyright}>&copy; Sebastian Powszuk</p>
                    <Languages currentLang={currentLang} navVariant={navVariant} />
                </div>
            </nav>
        </div>
    )
}
