import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import { containerBlack, containerWhite } from "./layout.module.scss"

const Layout = ({ children, lang, bodyColor }) => {
    return (
        <div>
            <Header siteTitle={"header.title"} lang={lang} />
            <main className={bodyColor === 'black' ? containerBlack : containerWhite }>
                {children}
            </main>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
