import React from "react"

export default function Logo(color) {
  let logoColor = color || 'white'
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="43.622" viewBox="0 0 72 43.622">
        <g data-name="Group 12" transform="translate(-940.551 -340.271)">
          <g data-name="Group 11" transform="translate(940.551 340.271)">
            <path data-name="Path 9"
                  d="M1029.749,340.283a8.221,8.221,0,0,0-5.791,2.855h0l-30.015,33.928,0,0a2.786,2.786,0,0,1-3.806.371l4.351,4A8.177,8.177,0,0,0,1006.52,381h0l30.015-33.928,0,0a2.785,2.785,0,0,1,3.806-.371l-4.35-4A8.161,8.161,0,0,0,1029.749,340.283Z"
                  transform="translate(-979.222 -340.271)" fill={logoColor.color} />
            <path data-name="Path 10"
                  d="M1152.853,388.774l-4.3-3.959a2.939,2.939,0,0,1,.151,3.742l-20.763,23.472a2.8,2.8,0,0,1-3.665.231l4.3,3.959a8.111,8.111,0,0,0,6.229,2.4,8.253,8.253,0,0,0,5.889-2.984l0,0,12.834-14.508,0,0a8.569,8.569,0,0,0,2.307-6.281A8.423,8.423,0,0,0,1152.853,388.774Z"
                  transform="translate(-1083.845 -375.013)" fill={logoColor.color} />
            <path data-name="Path 11"
                  d="M943.542,370.2l4.3,3.959a2.939,2.939,0,0,1-.151-3.742l20.763-23.472a2.8,2.8,0,0,1,3.665-.231l-4.3-3.959a8.111,8.111,0,0,0-6.229-2.4,8.252,8.252,0,0,0-5.889,2.984l0,0L942.864,357.84l0,0a8.571,8.571,0,0,0-2.307,6.281A8.422,8.422,0,0,0,943.542,370.2Z"
                  transform="translate(-940.551 -340.323)" fill={logoColor.color} />
          </g>
        </g>
      </svg>
    </>
  )
}