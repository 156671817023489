import PropTypes from "prop-types"
import React from "react"
import Nav from "./nav"
import * as styles from "./header.module.scss"

const Header = ({ lang }) => {
  return (
    <header className={styles.header}>
      <Nav lang={lang} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header