// extracted by mini-css-extract-plugin
export var currentUrlLight = "nav-module--current-url-light--3LtWD";
export var currentUrlDark = "nav-module--current-url-dark--2ZlfR";
export var navBg = "nav-module--nav-bg--1wnvd";
export var navigation = "nav-module--navigation--_4GIw";
export var navigation__checkbox = "nav-module--navigation__checkbox--eq0zU";
export var navigation__locales = "nav-module--navigation__locales--RGlNa";
export var navigation__button = "nav-module--navigation__button--1IbgB";
export var showNavButton = "nav-module--showNavButton--3A6A6";
export var navigation__background = "nav-module--navigation__background--2h8NH";
export var navigation__overlay = "nav-module--navigation__overlay--2cEMn";
export var navigation__nav = "nav-module--navigation__nav--3tmmC";
export var navigation__logoMobile = "nav-module--navigation__logo-mobile--1zBUO";
export var navigation__logoGreen = "nav-module--navigation__logo-green--1owC-";
export var navigation__logoWhite = "nav-module--navigation__logo-white--qPkJg";
export var navigation__logo = "nav-module--navigation__logo--36H89";
export var navigation__list = "nav-module--navigation__list--2pIVD";
export var navigation__listContainer = "nav-module--navigation__list-container--2GTyE";
export var navigation__link = "nav-module--navigation__link--9DRBX";
export var navigation__linkLight = "nav-module--navigation__link-light--XhOw0";
export var navigation__linkDark = "nav-module--navigation__link-dark--3CuQp";
export var navigation__footer = "nav-module--navigation__footer--17mR1";
export var navigation__footer__copyright = "nav-module--navigation__footer__copyright--3-_-Z";
export var navigation__icon = "nav-module--navigation__icon--2zol3";
export var navigation__bullets = "nav-module--navigation__bullets--2IipN";
export var navigation__bulletContainer = "nav-module--navigation__bullet-container--2uteJ";
export var navigation__checkmark = "nav-module--navigation__checkmark--27Rv-";
export var navigation__bullet = "nav-module--navigation__bullet--37_kJ";