import React from "react"
import Link from "gatsby-link"
import * as css from "./languages.module.scss"

const languages = ["pl", "en"]
export const urls = {
  pl: {
    start: { url: "/", name: "Start", section: "start" },
    about: { url: "/autor", name: "Poznaj", section: "autor" },
    projects: { url: "/projekty", name: "Obejrzyj", section: "projekty" },
    contact: { url: "/kontakt", name: "Napisz", section: "kontakt" }
  },
  en: {
    start: { url: "/en", name: "Start", section: "start" },
    about: { url: "/author", name: "Author", section: "author" },
    projects: { url: "/projects", name: "Projects", section: "projects" },
    contact: { url: "/contact", name: "Contact", section: "contacts" }
  }
}

const Languages = ({ currentLang, navVariant }) => {
  return (
    <div className={css.languages}>
      {languages.map(language => (
        <Link to={language === "pl" ? "/" : `/${language}/`} 
              key={language}
              className={`${css.languages__link} ${navVariant === 'light' ? css.languages__linkLight : css.languages__linkDark} ${currentLang === language ? css.languages__linkActive : ''}`}
        >{language.toUpperCase()}</Link>
      ))
      }
    </div>
  )
}

export default Languages
